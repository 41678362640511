import {
  getModule,
  Module,
  VuexModule,
  Mutation,
} from "vuex-module-decorators";
import { store } from "@/store/store";

@Module({
  namespaced: true,
  name: "CargandoModule",
  store,
  dynamic: true,
})
class CargandoModule extends VuexModule {
  public mostrar: boolean = false;
  @Mutation
  public ShowEspera() {
    this.mostrar = true;
  }
  @Mutation
  public CloseEspera() {
    this.mostrar = false;
  }
}
export default getModule(CargandoModule);
