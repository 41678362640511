import Swal from 'sweetalert2';
import CargandoModule from "@/store/modules/cargando-module";
export default class ServiceMessage {

    public TypeSuccess = 'success';
    public TypeError = 'error';
    public TypeWarning = 'warning';
    public TypeInfo = 'info';
    public TypeQuestion: any = 'question';

    public PositionTop = 'top';
    public PositionTopStart = 'top-start';
    public PositionTopEnd = 'top-end';
    public PositionCenter = 'center';
    public PositionCenterStart = 'center-start';
    public PositionCenterEnd = 'center-end';
    public PositionBottom = 'bottom';
    public PositionBottomStart = 'bottom-start';
    public PositionBottomEnd = 'bottom-end';

    private defaultTimeout = 3000;
    private customClass = '';
    //private animation = true;

    public setCustomClass(customClass: string) {
        this.customClass = customClass;
    }

    /*public setAnimation(animation: boolean) {
        this.animation = animation;
    }*/

    // Aviso flotante que desaparece solo tras un tiempo
    public toast(texto: string, tipo: any = this.TypeSuccess, timeout: number = this.defaultTimeout,
        posicion: any = this.PositionTop,
        customClass: any = this.customClass) {
        return Swal.fire({
            position: posicion,
            showConfirmButton: false,
            timer: timeout,
            title: texto,
            toast: true,
            icon:tipo,
            customClass
        });
    }

    // Aviso flotante que desaparece solo tras un tiempo
    public toastsuccesful(texto: string) {
        let tipo = this.TypeSuccess;
        let timeout = this.defaultTimeout;
        let position = this.PositionTop;
        let customClass = '';
        this.toast(texto, tipo, timeout, position, customClass);
    }

    // Aviso flotante que desaparece solo tras un tiempo
    public toastinfo(texto: string) {
        let tipo = this.TypeWarning;
        let timeout = 100000;// this.defaultTimeout;
        let position = this.PositionTop;
        let customClass = '';
        return this.toast(texto, tipo, timeout, position, customClass);
    }

    public alert(titulo: string, texto: string = '', tipo: any = this.TypeSuccess,
        timeout: number = this.defaultTimeout, posicion: any = this.PositionCenter,
        customClass: any = this.customClass) {
        Swal.fire({
            position: posicion,
            text: texto,
            timer: timeout,
            title: titulo,
            icon: tipo,
            customClass
        });
    }
    public alertRespose(titulo: string, texto: string = '', cb: (res: boolean) => void, tipo: any = this.TypeSuccess,
        timeout: number = this.defaultTimeout, posicion: any = this.PositionCenter,
        customClass: any = this.customClass) {
        Swal.fire({
            position: posicion,
            text: texto,
            timer: timeout,
            title: titulo,
            icon: tipo,
            customClass
        }).then((result: any) => {
            if (result.value) {
                cb(true);
            } else {
                cb(false);
            }
        });
    }
    public confirm(texto: string, titulo = 'Pregunta', cb: (res: boolean) => void,
        customClass: any = this.customClass) {
        Swal.fire({
            cancelButtonText: 'No',
            confirmButtonText: 'Sí',
            showCancelButton: true,
            text: texto,
            title: titulo,
            icon: this.TypeQuestion,
            customClass
        }).then((result: any) => {
            if (result.value) {
                cb(true);
            } else {
                cb(false);
            }
        });
    }

    public showLoading(customClass: any = this.customClass) {
        /* Swal({
             title: 'Cargando . . .',
             customClass,
         });
         Swal.showLoading();*/
        CargandoModule.ShowEspera()
    }

    public hideLoading() {
        // Swal.close();
        CargandoModule.CloseEspera()
    }
}

export const MessageService = new ServiceMessage();
